<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4" sm="6">
        <v-row>
          <v-col cols="12" class="d-flex align-center mt-2" style="flex: 1">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Пошук"
                color="grey darken-1"
                placeholder="Почніть вводити текст для пошуку"
                single-line
                hide-details
                class="pt-0 mt-0"
            />
            <v-btn class="ml-2" style="flex: 0 0 120px" small depressed color="grey lighten-2" @click="selectItem = { new: true }">
              Створити
            </v-btn>
          </v-col>
          <v-col cols="12" v-for="(item, idx) in filteredItems" :key="idx" class="py-2">
            <v-card @click="selectCurrentItem(item.id)" :class="selectItem.id === item.id ? 'active-card' : ''">
              <v-card-text class="pt-3 pb-0" :class="selectItem.id === item.id ? 'white--text' : ''">
                {{ item.short_name }}
              </v-card-text>
              <v-card-text class="pt-0 pb-3">
                <v-chip-group>
                  <v-chip small label>{{ item.code }}</v-chip>
                  <v-chip small label>{{ item.tax_group }}</v-chip>
                  <v-chip small label color="error lighten-1" v-if="item.disable">Відключено</v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8" sm="6" v-if="selectItem.new || selectItem.id">
        <Organization :item="selectItem" :bordered="false" :disable_fetch="true" @update_item="onOrganizationUpdate"></Organization>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "AdminOrganization",
  components: {
    Organization: () => import("@/components/Organization")
  },
  computed: {
    filteredItems() {
      if (!this.search) {
        return this.items
      } else {

        return this.items.filter(item => {
          const filters = [
            (item.full_name.toLowerCase().indexOf(this.search.toLowerCase())),
            (item.short_name.toLowerCase().indexOf(this.search.toLowerCase())),
            (item.code.toLowerCase().indexOf(this.search.toLowerCase()))
          ]

          if (this.search.toLowerCase().substring(0, 6) === 'не від') {
            filters.push((item.disable ? -1 : 0))
          }

          if (this.search.toLowerCase().substring(0, 5) === 'відкл') {
            filters.push((item.disable ? 0 : -1))
          }

          return filters.find(i => i !== -1) !== undefined
        })
      }
    }
  },
  data() {
    return {
      items: [],
      selectItem: {},
      newItem: false,
      search: '',
    }
  },
  methods: {
    onOrganizationUpdate(item) {
      const item_obj = this.items.find(i => i.id === item.id)
      if (!item_obj) {
        this.items.push(item_obj)
      } else {
        Object.keys(item).forEach(i => {
          item_obj[i] = item[i]
        })
      }
    },
    selectCurrentItem(id) {
      const item = this.items.find(item => item.id === id)
      if (!item) {
        this.selectItem = {}
      } else {
        this.selectItem = Object.assign({}, item)
      }
    },
    fetchItems() {
      return new Promise((resolve, reject) => {
        axios.get('/admin-panel/organizations')
          .then(response => response.data)
          .then(data => {
           this.items = data
            resolve(true)
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            reject(false)
          })
      })
    }
  },
  created() {
    this.fetchItems()
  }
}
</script>

<style scoped lang="scss">
  .active-card {
    background: #5a5a5a !important;
  }
</style>